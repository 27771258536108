<template>
  <div>
    <!-- <div> -->
    <!-- <van-field v-model="dataform.OrganName"
        is-link
        readonly
        placeholder="请选择所在地区"
        @click="show = true" />
      <van-popup v-model="show"
        position="bottom">
        <van-picker show-toolbar
          :columns="orgonList"
          value-key="organname"
          @confirm="ocodeQr"
          @cancel="show = false" />
      </van-popup> -->
    <!-- <van-popup v-model="show"
        round
        position="bottom">
        <van-cascader v-model="cascaderValue"
          title="请选择所在地区"
          :options="orgonList"
          @close="show = false"
          :field-names="{
              text: 'OName',
              value: 'OCode',
              children: 'Children',
            }"
          @finish="onFinish" />
      </van-popup> -->
    <!-- </div> -->
    <!-- 轮播图 -->
    <div style="height:180px;">
      <div class="header"> </div>
      <div class="personal"
        style="top: 5px;">
        <van-swipe class="my-swipe"
          :autoplay="3000"
          indicator-color="white"
          style="margin:5px 10px;border-radius: 5px;">
          <van-swipe-item class="homebanner"><img src="https://lycaljdapi.dexian.ren//upload/images/2021/12/2cf1991b-638.png" /></van-swipe-item>
          <!-- <van-swipe-item>2</van-swipe-item>
          <van-swipe-item>3</van-swipe-item>
          <van-swipe-item>4</van-swipe-item> -->
        </van-swipe>
      </div>
    </div>

    <div>
      <van-grid :border="false"
        :column-num="3">
        <van-grid-item @click="zhucan('/ylOrder/currentOrder/1'+ '/' + oCode)">
          <van-image src="https://lycaljdapi.dexian.ren//upload/images/2021/12/5912718d-442.png"
            style="width:50px;" />
          <span class="palace">助餐</span>
        </van-grid-item>
        <van-grid-item icon="photo-o"
          @click="zhucan('/ylOrder/currentOrder/4'+ '/' + oCode)">
          <van-image src="https://lycaljdapi.dexian.ren//upload/images/2021/12/6f010a0b-b43.png"
            style="width:50px;" />
          <span class="palace">助浴</span>
        </van-grid-item>
        <van-grid-item @click="zhucan('/ylOrder/currentOrder/2'+ '/' + oCode)">
          <van-image src="https://lycaljdapi.dexian.ren//upload/images/2021/12/afb6f88a-d5c.png"
            style="width:50px;" />
          <span class="palace">助洁</span>
        </van-grid-item>
        <van-grid-item icon="photo-o"
          @click="zhucan('/ylOrder/currentOrder/3'+ '/' + oCode)">
          <van-image src="https://lycaljdapi.dexian.ren//upload/images/2021/12/2aa400de-a75.png"
            style="width:50px;" />
          <span class="palace">助行</span>
        </van-grid-item>

        <van-grid-item icon="photo-o"
          @click="zhucan('/ylOrder/currentOrder/5'+ '/' + oCode)">
          <van-image src="https://lycaljdapi.dexian.ren//upload/images/2021/12/ebe994a8-768.png"
            style="width:50px;" />
          <span class="palace">助医</span>
        </van-grid-item>
        <van-grid-item icon="photo-o"
          @click="zhucan('/ylOrder/currentOrder/7'+ '/' + oCode)">
          <van-image src="https://lycaljdapi.dexian.ren//upload/images/2021/12/aedbac8e-1bb.png"
            style="width:50px;" />
          <span class="palace">巡访关爱</span>
        </van-grid-item>
        <van-grid-item icon="photo-o"
          @click="zhucan('/ylOrder/currentOrder/6'+ '/' + oCode)">
          <van-image src="https://lycaljdapi.dexian.ren//upload/images/2021/12/af5fc0a5-3c4.png"
            style="width:50px;" />
          <span class="palace">康复</span>
        </van-grid-item>
        <van-grid-item icon="photo-o"
          @click="zhucan('/ylOrder/currentOrder/8'+ '/' + oCode)">
          <van-image src="https://lycaljdapi.dexian.ren//upload/images/2021/12/d77e5880-570.png"
            style="width:50px;" />
          <span class="palace">辅助租赁</span>
        </van-grid-item>
        <van-grid-item icon="photo-o"
          @click="zhucan('/ylOrder/currentOrder/99'+ '/' + oCode)">
          <van-image src="https://lycaljdapi.dexian.ren//upload/images/2021/12/388f4f57-864.png"
            style="width:50px;" />
          <span class="palace">其他</span>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- <div style="margin:10px 0;">
      <van-notice-bar color="black"
        background="#FAFAFA"
        mode="link"
        style="padding:10px 10px 10px 0;">
        <div class="tost">信息通知</div><span style="line-height: 40px;">技术是开发它的人的共同灵魂。</span>
      </van-notice-bar>
    </div> -->
    <!-- <div class="packages">
      <div class="packagesName">养老服务包</div>
      <div style="border-radius: 5px;padding: 8px;background: white;">
        <van-swipe class="my-swipe"
          :autoplay="3000"
          indicator-color="white"
          :show-indicators="false"
          style="margin:10px;">
          <van-swipe-item class="homebanner"
            v-for="item in ServiceList"
            :key="item.SId">
            <router-link :to="'/ylOrder/ylOrder/orderData/' + item.SId"
              style="color:black;">
              <div style="float:left;width:40%;">
                <img :src='item.ThemeImgUrl' />
              </div>

              <div style="float:left;margin-left:5px;">
                <span style="line-height:30px;">服务等级：{{item.DemandLevelDesc}}<br />
                  <span v-if="item.ServiceTime != null">服务时长：{{item.SerDuration}}<br /></span>
                  <span v-else>服务时长：暂无<br /></span>
                  收费标准：{{item.Charges}}</span>

              </div>

              <div style="float: left;width: 100%;"
                class="van-ellipsis">{{item.SGNames}}</div>
            </router-link>

          </van-swipe-item>
        </van-swipe>
      </div>

    </div> -->
    <div style="width:95%;margin:5px auto;">
      <h3>养老服务套餐</h3>
      <div v-for="item in ServiceList"
        :key="item.SId">
        <div style="float: left;margin:5px 0;border-bottom:1px solid #C6C6C6;">
          <div style="float: left;"
            @click="rockDetail('/ylOrder/ylOrder/orderData/'+ item.SPId)">
            <div style="float:left;width:40%;">
              <img :src="item.ThemeImgUrl"
                onerror="onerror=null;src='https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png'"
                style="width:100%;" />
            </div>
            <div style="float:left;margin-left:10px;font-size: 14px;color: #646566;">
              <span style="line-height:30px;">服务等级：{{item.DemandLevelDesc}}<br />
                <span v-if="item.SerDuration != null && item.SerDuration != ''">服务时长：{{item.SerDuration}}<br /></span>
                <span v-else>服务时长：暂无<br /></span>
                收费标准：{{item.Cost}}</span>
            </div>
          </div>

          <div style="float: left;width: 60%;margin: 5px;font-size: 16px;color: rgb(100, 101, 102);"
            class="van-ellipsis">{{item.SGNames}}</div>
          <div style="float: right;margin: 5px;font-size: 16px;">
            <div class="placeOrder"
              @click="orderPlace(item)">我要下单</div>
          </div>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="orderPlaceshow"
      title="已选商品">
      <div class="content">
        <div>
          <div v-for="item in orderplaceList"
            :key="item.SId">
            <div style="float: left;margin:5px 10px;border-bottom:1px solid #C6C6C6;">
              <div style="float:left;width: 25%;">
                <img :src="item.SImgUrl"
                  style="width:100%;"
                  onerror="onerror=null;src='https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png'" />
              </div>

              <div style="float:left;margin-left:5px;font-size: 14px;color: #646566;">
                <span style="line-height:25px;">
                  <!-- <span style="font-size: 16px;font-weight: 600;">{{item.SName}}<br /></span> -->
                  <span style="color: red">￥{{item.SPrice}}{{item.SMUnit}}<br /></span>
                  <span>总价：{{item.SAmount}}<br /></span>
                  {{item.StaName}}</span>

              </div>
              <div style="float: left;width: 60%;margin: 5px;font-size: 16px;color: rgb(100, 101, 102);"
                class="van-multi-ellipsis--l2">{{item.SName}}</div>
              <div style="padding: 10px;width: 30%;float: right;">
                <van-stepper v-model="item.SNum"
                  theme="round"
                  min="0"
                  style="right: 20px;top: 110px;"
                  button-size="22"
                  disable-input
                  @plus="changeAdd(item)"
                  @minus="changeDelet(item)"
                  @change="change" />
              </div>

            </div>

          </div>
        </div>
        <!-- <div style="">
          <div style="width: 100%; bottom: 0; position: fixed; padding: 10px">
            <van-button round
              type="primary"
              size="normal"
              style="font-size: 16px; width: 95%"
              color="black"
              @click="placeOrder()">
              下单
            </van-button>
          </div>
        </div> -->
        <div style="">
          <div style="width: 100%; bottom: 0; position: fixed; padding: 10px">
            <van-button round
              type="primary"
              size="normal"
              style="font-size: 16px; width: 95%"
              color="black"
              @click="orderRen()">
              下单
            </van-button>
          </div>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="orderRenshow"
      title="配送老人"
      @close='close()'>
      <div class="content">

        <van-radio-group v-model="oldManList"
          v-for="item in MyBindInfoList"
          :key="item.BindId"
          style="margin: 10px;padding:15px 10px;background: #f6f7f8;border-radius: 5px;">
          <van-radio :name="item.BindId"
            checked-color="#ffc002"
            style="padding: 20rpx;">
            <div style="font-size: 18px;font-weight: 600;margin-bottom: 10rpx;margin: 5px;">
              {{item.OMName}}
              <span style="font-weight: 500;">{{item.OMMobile}}</span>
              <span style="padding-left: 20px;"
                @click="updata(item)">
                <van-icon name="edit" /></span>
              <span style="padding-left: 20px;"
                @click="add()">
                <van-icon name="add-o" /></span>
            </div>
            <!-- <div style="font-size: 14px;margin-bottom: 10rpx;">{{item.Summary}}</div> -->
            <div style="margin: 5px;">{{item.OMAddr}}</div>
          </van-radio>
        </van-radio-group>
        <div style="">
          <div style="width: 100%; bottom: 0; position: fixed; padding: 10px">
            <van-button round
              type="primary"
              size="normal"
              style="font-size: 16px; width: 95%"
              color="black"
              @click="placeOrder()">
              确定
            </van-button>
          </div>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="orderRenAddshow"
      title="配送老人信息"
      @close='close()'>
      <div class="content">
        <van-field v-model="oldmanForm.OMIdCard"
          class="shuru"
          name="oldmanForm.OMIdCard"
          label="身份证号"
          placeholder="请输入身份证号" />
        <van-field v-model="oldmanForm.OMMobile"
          class="shuru"
          name="oldmanForm.OMMobile"
          label="联系电话"
          placeholder="请输入老人联系电话" />
        <van-field v-model="oldmanForm.OMAddr"
          type="textarea"
          rows="1"
          autosize
          class="shuru"
          name="oldmanForm.OMAddr"
          label="居住地址"
          placeholder="请输入老人现居住地址" />
        <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
          <van-button round
            block
            type="info"
            color="black"
            @click="submit()">保存</van-button>
        </div>
      </div>
    </van-action-sheet>
    <div class="hidden">没有更多了~</div>
  </div>
</template>

<script>
import {
  WeGetServiceList,
  WeGetSerPackageList,
  WeGenerateOrder,
  WeSaveOrderPackage,
  WeGetOrderServiceList,
  WeDeleteOrderService,
  WePlaceOrder,
  WeGetMyRegInfo,
  WeGetMyBindInfoPage,
  WeSaveBindInfo
} from "@/api/ylOrder.js";
import { setOpenId, getOpenId, setOrderId, getOrderId, removeOrderId } from "@/utils/auth";
import { GetChildOrgansNullToken } from "@/api/Organ";
import Vue from 'vue';
import { Toast } from 'vant';
import wx from "weixin-js-sdk";

Vue.use(Toast);
export default {
  data () {
    return {
      ServiceList: [],
      show: false,
      orderPlaceshow: false,
      orderRenshow: false,
      orderRenAddshow: false,
      orgonList: [],
      orderplaceList: [],
      MyBindInfoList: [],
      oldManList: "",
      cascaderValue: "",
      dataform: {
        OrgName: '',
        OrgCode: '',
      },
      oldmanForm: {
        OMIdCard: '',
        OMMobile: '',
        OMAddr: '',
        BindId: undefined
      },
      orderId: "",
      sum: "",
      count: 1,
      sumid: "",
      addOrder: "",
      oCode: undefined,
    }
  },
  created () {
    //获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.getUser()
    // this.getServiceList();
    this.WeGetProServicet();
  },
  methods: {
    getOrgonTree () {
      GetChildOrgansNullToken({ organid: '161' }).then((res) => {
        this.orgonList = res.data.data;
        this.dataform.OrganName = res.data.data[0].organname
        this.oCode = res.data.data[0].organcode
      });
    },
    getUser () {
      WeGetMyRegInfo({ openID: getOpenId() }).then((res) => {
        if (res.data.code == '0') {
          this.userXq = res.data.data
          this.dataform.OrganName = res.data.data.BindOrgName
          this.oCode = res.data.data.BindOrgCode
          this.getOrgonTreeChang()
        } else {
          this.getOrgonTree()
        }

      });
    },
    // 绑定时
    getOrgonTreeChang () {
      GetChildOrgansNullToken({ organid: '161' }).then((res) => {
        this.orgonList = res.data.data;
      });
    },
    ocodeQr (val) {
      console.log(val);
      this.oCode = val.organcode;
      this.dataform.OrganName = val.organname;
      // this.hzlist.HouserStatusName = val.Name;
      this.show = false;
    },
    // 地区选择完成后 触发事件
    onFinish ({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.OName).join("/");
      this.OCode = selectedOptions.map((option) => option.OCode).join("/");
      this.dataform.OrgName = this.fieldValue;
      if (selectedOptions[2] == undefined) {
        this.dataform.OrgCode = selectedOptions[1].OCode;
        console.log(this.dataform.OrgCode);
        this.oCode = this.dataform.OrgCode
      } else {
        this.dataform.OrgCode = selectedOptions[2].OCode;
      }
    },
    getServiceList: function (row) {
      WeGetServiceList().then((res) => {
        this.ServiceList = res.data.data;
      });
    },
    WeGetProServicet: function (row) {
      WeGetSerPackageList().then((res) => {
        this.ServiceList = res.data.data;
      });
    },
    zhucan: function (row) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + row,
        });
      } else {
        this.$router.push(row);
      }
      // this.$router.push("/ylOrder/currentOrder/" + row + "/" + this.oCode);
    },
    rockDetail: function (row) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + row,
        });
      } else {
        this.$router.push(row);
      }
      // this.$router.push("/ylOrder/ylOrder/orderData/" + row);
    },
    getMyBindInfoPage: function () {
      WeGetMyBindInfoPage({ openID: getOpenId(), page: '1', limit: '999' }).then((res) => {
        if (res.data.code == '0') {
          if (res.data.data.length != '0') {
            this.MyBindInfoList = res.data.data;
            // this.orderListShow = false
          } else {
            this.MyBindInfoList = []
            this.orderRenshow = false
            this.orderRenAddshow = true

            // Toast.fail('未绑定老人信息');
            // this.orderListShow = true
          }
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    onAdd: function (row) {
      console.log(row);
    },
    close: function () {
      // console.log(123123);
      this.oldManList = ''
      this.oldmanForm.OMIdCard = ''
      this.oldmanForm.OMMobile = ''
      this.oldmanForm.OMAddr = ''
    },
    // 下单人
    orderRen: function (row) {
      this.orderRenshow = true
      this.getMyBindInfoPage()
    },
    // 下单弹窗
    orderPlace: function (row) {
      this.orderPlaceshow = true
      if (this.sumid != row.SPId) {
        this.count = 1
        this.sum = 1;
      }
      if (getOrderId() != undefined && getOrderId() != '') {
        this.saveOrderService(row.SPId);
      } else {
        WeGenerateOrder({
          OType: 2,
          OSerKind: 0,
          OpenID: getOpenId(),
        }).then((res) => {
          if (res.data.code == "0") {
            this.sumid = row.SSId
            setOrderId(res.data.data);
            this.sum = this.count++;
            this.saveOrderService(row.SPId);
          } else {
            Toast.fail(res.data.msg);
          }
        });
      }

    },
    //保存订单
    saveOrderService: function (row) {
      WeSaveOrderPackage({
        SPId: row,
        OrderId: getOrderId(),
        OpenID: getOpenId(),
        SNum: this.sum,
      }).then((res) => {
        if (res.data.code == "0") {
          this.orderPlaceshow = true
          this.getOrderServiceList()
        } else if (res.data.code == "2") {
          console.log(123123);
          this.orderPlaceshow = true
          removeOrderId()
          // this.getOrderServiceList()
        }
        this.getOrderServiceList()
      });
    },
    //已下订单列表
    getOrderServiceList: function () {
      WeGetOrderServiceList({ openID: getOpenId(), orderId: getOrderId() }).then(
        (res) => {
          if (res.data.code == '0') {
            this.orderplaceList = res.data.data;
          } else {
            Toast.fail(res.data.msg);
          }
          // this.addOrder = res.data.data[0].OrderId
        }
      );
    },
    //添加数量
    changeAdd: function (row) {
      console.log(row);
      // this.OrderId = row.OrderId
      this.SId = row.SId
    },
    //删除数量
    changeDelet: function (row) {
      // this.OrderId = row.OrderId
      this.OSId = row.OSId
      this.SId = row.SId
    },
    //数量
    change: function (value) {
      console.log(value);
      if (value == '0') {
        this.deleteOrderService()
      } else {

        this.changeOrderService(value)
      }

    },
    // 添加删除后保存
    changeOrderService: function (row) {
      WeSaveOrderPackage({
        SPId: this.SId,
        OrderId: getOrderId(),
        OpenID: getOpenId(),
        SNum: row,
      }).then((res) => {
        if (res.data.code == "0") {
          console.log(res.data.data);
          this.changeOrderServiceList()
        }
      });
    },
    // 删除订单服务项目
    deleteOrderService: function () {
      WeDeleteOrderService({
        OSId: this.OSId,
        OrderId: getOrderId(),
        OpenID: getOpenId(),
      }).then((res) => {
        if (res.data.code == "0") {
          Toast.fail('已删除');
          this.changeOrderServiceList()
          // this.orderPlaceshow = false;
        }
      });
    },
    changeOrderServiceList: function () {
      WeGetOrderServiceList({ openID: getOpenId(), orderId: getOrderId() }).then(
        (res) => {
          if (res.data.data.length != '0') {
            this.orderplaceList = res.data.data;
            this.addOrder = res.data.data[0].OrderId
          } else {
            this.show = false;
            this.orderPlaceshow = false;
          }

        }
      );
    },
    //下单
    placeOrder: function () {
      console.log(this.oldManList);
      if (this.oldManList == '') {
        Toast.fail('请选择需要配送的老人信息');
      } else {
        WePlaceOrder({ OpenID: getOpenId(), OrderId: getOrderId(), BindId: this.oldManList }).then(
          (res) => {
            if (res.data.code == '0') {
              Toast.success('下单成功');
              removeOrderId()
              this.count = ''
              this.sum = ''
              this.sumid = ''
              this.orderplaceList = []
              this.show = false;
              this.orderPlaceshow = false;
              this.orderRenshow = false
            } else {
              Toast.fail(res.data.msg);
            }
          }
        );
      }
    },
    // 添加老人信息
    submit () {
      console.log(this.oldmanForm);
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!reg.test(this.oldmanForm.OMIdCard)) {
        Toast.fail("身份证号码不正确");
      } else if (this.oldmanForm.OMMobile == '') {
        Toast.fail("请输入老人联系电话");
      } else if (this.oldmanForm.OMAddr == '') {
        Toast.fail("请输入老人现居住地址");
      } else {
        WeSaveBindInfo(
          {
            OpenID: getOpenId(),
            OMIdCard: this.oldmanForm.OMIdCard,
            OMMobile: this.oldmanForm.OMMobile,
            OMAddr: this.oldmanForm.OMAddr,
            BindId: this.oldmanForm.BindId,
          }
        ).then((res) => {
          if (res.data.code == '0') {
            Toast.success('绑定成功');
            this.orderRenAddshow = false
            this.orderRenshow = true
            this.oldmanForm.OMIdCard = ''
            this.oldmanForm.OMMobile = ''
            this.oldmanForm.OMAddr = ''
            this.oldmanForm.BindId = undefined
            this.getMyBindInfoPage()
          } else {
            Toast.fail(res.data.msg);
          }

        });
      }
    },
    add: function () {
      this.orderRenAddshow = true
    },
    // 编辑老人信息
    updata: function (row) {
      this.orderRenAddshow = true
      console.log(row);
      this.oldmanForm = Object.assign({}, row);
    },
  }
}
</script>

<style scoped>
.header {
  position: relative;
  height: 120px;
  background-image: url('https://lycaljdapi.dexian.ren//upload/images/2021/12/f9ec9395-fc1.png');
  background-size: 100% 120px;
}
.personal {
  position: absolute;
  width: 100%;
  top: 5px;
  align-items: center;
  text-align: center;
}
.homebanner img {
  width: 100%;
  vertical-align: top;
}
.homebanner {
  font-size: 14px;
  color: #646566;
}
.tost {
  float: left;
  background: #d81e06;
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 7px;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 5px;
}
.packages {
  margin: 10px;
  padding: 7px;
  background: #ffc002;
  border-radius: 5px;
}
.packagesName {
  color: white;
  font-size: 18px;
  font-weight: 600;
  padding: 0 7px 7px 7px;
}
.palace {
  color: #646566;
  font-size: 12px;
  margin-top: 5px;
}
.van-cell {
  background: #ffc002 !important;
  border-bottom: 1px solid #ffc002 !important;
}
.shuru {
  background: white !important;
  border-bottom: 1px solid #f8f5eb !important;
}
/deep/.van-grid-item__content--center {
  padding: 8px 8px !important;
}
.placeOrder {
  width: 100%;
  text-align: center;
  float: right;
  padding: 2px 5px;
  /* border: 1px solid; */
  border-radius: 10px 0;
  font-size: 18px;
  background-color: #ffc002;
}
.van-action-sheet {
  height: 500px !important;
}
.van-action-sheet__content {
  margin-bottom: 60px !important;
}
/deep/.van-stepper--round .van-stepper__plus {
  background-color: #ffc002 !important;
}
/deep/.van-stepper--round .van-stepper__minus {
  background-color: #ffc002 !important;
  border: 1px solid #ffc002 !important;
  color: #fff !important;
}
.hidden {
  margin: 10px 0;
  font-size: 10px;
  color: #ccc;
  text-align: center;
}
</style>